const get_started = {
    page_title: 'Loslegen',
    title: 'Etwas zum Erkunden, um dir zu helfen, erfolgreich zu sein',
    subtitle: 'Einige Dinge, die Sie tun können, um schnell den Wert von Logto zu erhalten',
    develop: {
        title: 'Entwicklung: Nehmen Sie sich 5 Minuten Zeit, um Ihre App zu integrieren',
        title_cloud: 'Entwicklung: Integrieren Sie Ihre App in Minuten sicher',
        subtitle_cloud: 'Oder nehmen Sie 5 Minuten Zeit, um Ihre App mit unseren vorgefertigten SDKs und Tutorials zu integrieren.',
    },
    customize: {
        title: 'Anpassen: Liefern Sie ein großartiges Anmeldeerlebnis',
        preview: {
            title: 'Überprüfen Sie die Live-Vorschau des gerade angepassten Anmeldeerlebnisses',
            subtitle: 'Testen Sie jetzt das Logto-Anmeldeerlebnis, um zu sehen, wie es funktioniert',
        },
        connector: {
            title: 'Fügen Sie weitere Connectors hinzu, um weitere soziale Anmeldeverfahren zu unterstützen',
            subtitle: 'Probieren Sie die passwortlose Anmeldung aus und ermöglichen Sie Ihren Kunden ein sicheres und reibungsloses Erlebnis',
        },
        continue_customizing: 'Mit der Anpassung fortfahren',
        try_now: 'Jetzt ausprobieren',
        add_more: 'Mehr hinzufügen',
    },
    secure: {
        title: 'Sichern: Schützen Sie Ihre Ressourcen',
    },
    manage: {
        title: 'Verwalten: Definieren Sie Zugriffssteuerung für Ihr Produkt und Ihre Benutzer',
        rbac: {
            title: 'Fügen Sie rollenbasierte Zugriffssteuerung hinzu, um Ihre Ressourcen zu schützen',
            subtitle: 'Steuern Sie Ihre Ressource durch skalierbare Rollenautorisierung für verschiedene Anwendungsfälle.',
        },
        create_roles: 'Rollen erstellen',
    },
    view_all: 'Alle anzeigen →',
};
export default Object.freeze(get_started);
