const invitation = {
    find_your_tenants: 'Finde deine Mieter',
    find_tenants_description: 'Ihre E-Mail-Adresse ist möglicherweise bereits bei mehreren Mietern registriert. Sie können wählen, einem vorhandenen beizutreten oder fortfahren, einen neuen zu erstellen.',
    create_new_tenant: 'Neuen Mieter erstellen',
    email_not_match_title: 'Sie sind derzeit angemeldet als\n{{email}}',
    email_not_match_description: 'Melden Sie sich bitte mit dem richtigen Konto an, um die Einladung anzunehmen und Mitglied der Organisation zu werden.',
    switch_account: 'An ein anderes Konto anmelden',
    invalid_invitation_status: 'Ungültige Einladung. Bitte kontaktieren Sie den Administrator und versuchen Sie es erneut.',
    invitation_not_found: 'Einladung nicht gefunden. Bitte kontaktieren Sie den Administrator.',
};
export default Object.freeze(invitation);
