const demo_app = {
    title: 'Sie haben sich erfolgreich bei der Live-Vorschau angemeldet!',
    subtitle: 'Hier sind Ihre Benutzerinformationen:',
    username: 'Benutzername: ',
    user_id: 'Benutzer ID: ',
    sign_out: 'Abmeldung von der Live-Vorschau',
    continue_explore: 'Oder weiter zum Entdecken',
    customize_sign_in_experience: 'Anmeldeoberfläche anpassen',
    enable_passwordless: 'Passwordless einschalten',
    add_social_connector: 'Social Connector hinzufügen',
};
export default Object.freeze(demo_app);
